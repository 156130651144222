<template>
	<w-layout column>
		<w-flex>
			<w-layout row justify-center align-center>
				<w-color-picker v-model="application.color" :label="colorFieldName"/>
			</w-layout>
		</w-flex>
	</w-layout>
</template>
<script>
export default {
	name: 'MobileAppColors',
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		colorFieldName: function () {
			return this.$t('mobileapp.fields.color')
		},
		accountingFirms: function () {
			return this.application.accounting_firms
		}
	},
	watch: {
		accountingFirms: {
			immediate: true,
			handler: function () {
				this.loadColor()
			}
		}
	},
	mounted: function () {
		if (!this.application.color) {
			this.loadColor()
		}
	},
	methods: {
		loadColor: function () {
			if (this.accountingFirms?.length >= 1 && this.accountingFirms[0].whitelabels) {
				let primaryColor = '#'
				const accountingFirm = this.application.accounting_firms[0]
				primaryColor = accountingFirm?.whitelabels[0]?.primary_color?.toUpperCase()
				this.application.color = primaryColor
			}
		}
	}
}
</script>
